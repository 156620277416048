import {useState, useContext} from 'react'

import Typography from '@mui/material/Typography'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { useTranslation } from 'react-i18next'


const DeleteInvoicesDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('overview')

	const company = getLocalStorageCompany()

	const {setMessage} = useContext(MessageContext)

	const handleConfirm = () => {
		const invoice_actions = new InvoiceActions()
		invoice_actions.deleteMultiple(ids)
		.then(data => {
			console.log(data)
			setMessage(props => ({...props, success: t("invoices_deleted")}))
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
		})
		.finally(async () => {
			return state_manager.setGetAction(undefined, undefined, {'approved': 'False', 'paid': 'False'})
			.then(() => {
				handleClose()
			})
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('are_you_sure_you_want_to_delete')} {ids.length} {ids.length === 1 ? <span>{t('invoice')}</span> : <span>{t('invoices')}</span>}
			</Typography>
			<DialogButton variant='contained' onClick={handleConfirm}> {t('delete_invoices')} </DialogButton>
		</INastixDialog>
	)
}


const DeleteInvoicesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('overview')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('delete_invoices')} </Typography>
		</TableActionButon>
		<DeleteInvoicesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default DeleteInvoicesDialogHandler