import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getLocalStorageUser } from '@utils/localStorage/user'


const DashboardNavDropdown = () => {
	const navigate = useNavigate()

    const user = getLocalStorageUser()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleDashboardButtonClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleDashboardMenuClose = () => {
		setAnchorEl(null)
	}

    const handleClick = (path: string) => {
		navigate("/" + path)
	}

    return (
        <>
        <Button
            aria-controls={open ? 'dashboard-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleDashboardButtonClick}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
                fontSize: '1rem', 
                textTransform: 'capitalize', 
                fontWeight: 400, 
                padding: '1.2rem',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.15)'
                }
            }}
        >
            Dashboard
        </Button>
        <Menu
            MenuListProps={{
                'aria-labelledby': 'dashboard-menu-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleDashboardMenuClose}
        >
            <MenuItem onClick={() => handleClick('dashboard/franchise')} disableRipple>
                Your Franchise
            </MenuItem>
            {/* {process.env.REACT_APP_ENVIRONMENT === 'development' && <MenuItem onClick={() => handleClick('dashboard/instructor')} disableRipple>
                Your Instructor
            </MenuItem>} */}
            {user.is_instructor &&
                <MenuItem onClick={() => handleClick('dashboard/instructor')} disableRipple>
                    Your Instructor
                </MenuItem>
            }
        </Menu>
        </>
    )
}

export default DashboardNavDropdown