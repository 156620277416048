import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import BulkChildActions from '@actions/ChildActions/bulkChildActions'
import { ChildAuthorisation } from '@utils/interfaces/interfaces'


const BulkChildAuthorisationsDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('send_bulk_email')

	const handleBulkAuthorisations = async (): Promise<any> => {
		const bulk_child_actions = new BulkChildActions()
        bulk_child_actions.updateToAuthorised({children_ids: ids, authorized: ChildAuthorisation.AUTHORISED})
        .then(data => {
            console.log(data)
            state_manager.setUpdateMultipleAction(data)
            handleClose()
        })
        .catch(error => {
            console.log(error.message)
        })
	}

	const handleClose = () => {
		onClose()
	}

	//const { t } = useTranslation('send_bulk_email')

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Authorise Children </INastixDialogTitle>
			<DialogDivider/>
			<Button onClick={handleBulkAuthorisations} variant="contained" sx={{width: '100%'}}>
                Update
            </Button>
		</INastixDialog>
	)
}


const BulkChildAuthorisationsDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
			<Typography> Authorise </Typography>
		</TableActionButon>
		<BulkChildAuthorisationsDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default BulkChildAuthorisationsDialogHandler