import { useContext, useEffect, useState } from "react"

import VerifiedIcon from '@mui/icons-material/Verified'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { TableActionButon } from "@styles/Buttons"
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog"

import { DialogComponentProps } from "@components/tables/Table/Table"
import { MessageContext } from "@contexts/MessageContext"
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { useTranslation } from "react-i18next"
import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions"


const ChildTermlyAuthorisation = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('payments')

    const {setMessage} = useContext(MessageContext)

    const [term, setTerm] = useState('')

    const handleSend = () => {
        
        const child_payment_actions = new ChildPaymentsActions()
        const data = {
            'ids': ids,
            'payment_structure': 'termly',
            'term': term
        }
        child_payment_actions.create(data)
        .then(data => {
            console.log(data)
            setMessage(props => ({...props, success: t("children_marked_as_paid")}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
        .finally(async () => {
            return state_manager.setGetAction(undefined, undefined, {'payment_structure': 'termly'})
            .then(() => {
                handleClose()
            })
        })
    }

    const handleClose = () => {
		onClose()
	}

    const handleTermChange = (event: SelectChangeEvent) => {
        setTerm(event.target.value as string)
    }

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('which_term_would_you_like_to_mark_as_paid_for')} {ids.length} {ids.length === 1 ? <span>{t('child')}</span> : <span>{t('children')}</span>}
			</Typography>
            <br/>
            <FormControl fullWidth>
                <InputLabel id="term-select-label">{t('term')}</InputLabel>
                <Select
                    labelId="term-select-label"
                    id="term-select"
                    name="term"
                    label={t('term')}
                    value={term}
                    onChange={handleTermChange}
                    sx={{marginBottom: 2}}
                    required
                >
                    <MenuItem value={'term_1'}>{t('term_1')}</MenuItem>
                    <MenuItem value={'term_2'}>{t('term_2')}</MenuItem>
                    <MenuItem value={'term_3'}>{t('term_3')}</MenuItem>
                    <MenuItem value={'term_4'}>{t('term_4')}</MenuItem>
                </Select>
            </FormControl>
			<DialogButton variant='contained' onClick={handleSend}> {t('confirm')} </DialogButton>
		</INastixDialog>
	)
}

const ChildTermlyAuthorisationHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    const { t } = useTranslation('payments')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('authorise')} </Typography>
            </TableActionButon>
            <ChildTermlyAuthorisation ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
        </>
    )
}

export default ChildTermlyAuthorisationHandler