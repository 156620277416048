import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'

import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import NotificationTable from '@components/tables/Custom/NotificationTable'
import CompanyNotificationTable from '@components/tables/Custom/CompanyNotificationsTable'
import CompanyNotificationActions from '@actions/CRUDActions/NotificationActions/companyNotifcationActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { companyIsInGroup, companyPermissionValid, permissionValid } from '@utils/utils/util'
import { CompanyPermissions, UserPermissions } from '@utils/enums/permissions'
import { NumOfCompanyNotificationsContext, NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { CompanyType } from '@utils/enums/enums'



interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
		{value === index && (
			<Box sx={{ p: 3 }}>
			{children}
			</Box>
		)}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}
const notification_actions = new NotificationActions()
const company_notification_actions = new CompanyNotificationActions()

const NotifcationsTabs = () => {
	const {num_of_notifications} = useContext(NumOfNotificationsContext)
	const {num_of_company_notifications} = useContext(NumOfCompanyNotificationsContext)

	const [value, setValue] = React.useState(0)

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

	const is_franchise = companyIsInGroup(company, CompanyType.FRANCHISEE)

	const { t } = useTranslation('notifications_table')

	const handleChange = (event: React.SyntheticEvent, new_value: number) => {
		setValue(new_value)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{permissionValid(user, UserPermissions.VIEW_NOTIFICATIONS ) && 
						<Tab 
							label={
								<Badge
									badgeContent={num_of_notifications} 
									color="error"
									sx={{'.MuiBadge-badge': {right: '-10px'}}}
								> 
									{t('user')} 
								</Badge>
							} 
							{...a11yProps(0)} 
						/>
					}
					{(permissionValid(user, UserPermissions.VIEW_COMPANY_NOTIFICATIONS) && 
						companyPermissionValid(company, CompanyPermissions.VIEW_COMPANY_NOTIFICATIONS)) && 
						<Tab 
							label={
								<Badge
									badgeContent={num_of_company_notifications} 
									color="error"
									sx={{
										padding: num_of_company_notifications ? '0 1rem 0 0.5rem' : '0',
									}}
								> 
									{is_franchise ? t('school_tab') : t('franchise_or_school_tab')} 
								</Badge>
							} 
							{...a11yProps(1)} 
						/>					
					}
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
        	    <NotificationTable action={notification_actions}/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<CompanyNotificationTable action={company_notification_actions}/>
			</TabPanel>
		</Box>
	)
}

export default NotifcationsTabs