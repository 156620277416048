import { useContext, useEffect, useState } from "react"

import VerifiedIcon from '@mui/icons-material/Verified'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { TableActionButon } from "@styles/Buttons"
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog"

import { DialogComponentProps } from "@components/tables/Table/Table"
import { MessageContext } from "@contexts/MessageContext"
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { useTranslation } from "react-i18next"
import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions"


const ChildMonthlyAuthorisation = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('payments')

    const {setMessage} = useContext(MessageContext)

    const [month, setMonth] = useState('')

    const handleSend = () => {
        
        const child_payment_actions = new ChildPaymentsActions()
        const data = {
            'ids': ids,
            'payment_structure': 'monthly',
            'month': month

        }
        child_payment_actions.create(data)
        .then(data => {
            console.log(data)
            setMessage(props => ({...props, success: t("children_marked_as_paid")}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
        .finally(async () => {
            return state_manager.setGetAction(undefined, undefined, {'payment_structure': 'monthly'})
            .then(() => {
                handleClose()
            })
        })
    }

    const handleClose = () => {
		onClose()
	}

    const handleMonthChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value as string)
    }

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{'Which month would you like to mark as paid for '} {ids.length} {ids.length === 1 ? <span>{t('child')}</span> : <span>{t('children')}</span>}
			</Typography>
            <br/>
            <FormControl fullWidth>
                <InputLabel id="month-select-label">{t('month')}</InputLabel>
                <Select
                    labelId="month-select-label"
                    id="month-select"
                    name="month"
                    label={t('month')}
                    value={month}
                    onChange={handleMonthChange}
                    sx={{marginBottom: 2}}
                    required
                >
                    <MenuItem value={'feb'}>{t('february')}</MenuItem>
                    <MenuItem value={'mar'}>{t('march')}</MenuItem>
                    <MenuItem value={'apr'}>{t('april')}</MenuItem>
                    <MenuItem value={'may'}>{t('may')}</MenuItem>
                    <MenuItem value={'jun'}>{t('june')}</MenuItem>
                    <MenuItem value={'jul'}>{t('july')}</MenuItem>
                    <MenuItem value={'aug'}>{t('august')}</MenuItem>
                    <MenuItem value={'sep'}>{t('september')}</MenuItem>
                    <MenuItem value={'oct'}>{t('october')}</MenuItem>
                    <MenuItem value={'nov'}>{t('november')}</MenuItem>
                </Select>
            </FormControl>
			<DialogButton variant='contained' onClick={handleSend}> {t('confirm')} </DialogButton>
		</INastixDialog>
	)
}

const ChildMonthlyAuthorisationHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    const { t } = useTranslation('payments')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('authorise')} </Typography>
            </TableActionButon>
            <ChildMonthlyAuthorisation ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
        </>
    )
}

export default ChildMonthlyAuthorisationHandler