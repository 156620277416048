import { Dayjs } from 'dayjs'

import { Franchisee, LocalStorageCompany, LocalStorageUser, Term, TermWithIndex, UserGroup } from "@utils/interfaces/interfaces"
import { UserType, CompanyType, InastixSystemUserType } from "@utils/enums/enums"
import { getLocalStorageCompany } from "@utils/localStorage/company"

import log from "loglevel"
import { FranchiseOption } from '@pages/Dashboard'


/**
 * Checks whether a given user or company has a specific permission. It iterates through the groups associated with the user or company and checks if any of the permissions in those groups match the specified search_permission.
 * @param user_or_company: (LocalStorageUser | LocalStorageCompany): An object representing a user or company, which contains information about their groups and permissions.
 * @param search_permission: (string): The codename of the permission to search for.
 * @returns boolean: Returns true if the permission is found, indicating that the user or company has the specified permission. Returns false if the permission is not found or if the user or company has no associated groups.
 */
const permissionValid = (user: LocalStorageUser, search_permission: string) => {
	if (!user.groups && user.permissions)
		// if user doesn't have groups or permissions prop then return false
		return false
	
	const company = getLocalStorageCompany()
	const user_group: UserGroup | undefined = getUserGroup(user, company)

	if (!user_group)
		return false

	const all_permissions = user_group.permissions.concat(user.permissions)

	for (const permission of all_permissions) {
		if (permission.codename === search_permission) {
			return true
		}
	}
	return false
}
const companyPermissionValid = (company: LocalStorageCompany, search_permission: string) => {
	if (!company.groups)
		// if company doesn't have groups prop then return false
		return false
	
	for (const group of company.groups) {
		for (const permission of group.permissions) {
			if (permission.codename === search_permission) {
				return true
			}
		}
	}
	return false
}


/**
 * Retrieves the UserGroup associated with all the LocalStorageUser groups and a parsed in LocalStorageCompany.
 *
 * @param user - The LocalStorageUser for which to retrieve the UserGroup.
 * @param tenant - The LocalStorageCompany representing the company or tenant.
 * @returns The UserGroup associated with the provided user and tenant.
 * @throws If the user does not have a matching UserGroup for the given tenant.
*/
const getUserGroup = (user: LocalStorageUser, tenant: LocalStorageCompany): UserGroup | undefined => {
	const user_group: UserGroup | undefined = user.groups.find(group => group.tenant === tenant.company_uuid)  // index by 0 because will be unique
	return user_group
}


/**
 * Checks if a LocalStorageUser is a member of the specified user group or groups.
 *
 * @param user - The LocalStorageUser to check for group membership.
 * @param search_group - The user group or an array of user groups to check against.
 * @returns `true` if the user is a member of the specified group(s), otherwise `false`.
*/
const isInGroup = (user: LocalStorageUser, company: LocalStorageCompany, search_group: UserType | UserType[], check_is_instructor: boolean=true) => {
	if (!user?.groups)
		return false
	
	if (Array.isArray(search_group)) {
		for (const search_group_el of search_group) {
			const user_tenant_group: UserGroup | undefined = user.groups.find(group => 
				(group.name === search_group_el || (check_is_instructor && (search_group_el === UserType.INSTRUCTOR && user.is_instructor))) && 
				(Object.keys(company).length ? group.tenant === company.company_uuid : true)
			)
			if (!!user_tenant_group)
				return true
		}
		return false
	}

	const user_tenant_group: UserGroup | undefined = user.groups.find(group => 
			(group.name === search_group || (search_group === UserType.INSTRUCTOR && user.is_instructor)) && 
			(Object.keys(company).length ? group.tenant === company.company_uuid : true)
		)

	return !!user_tenant_group
}


const isAMemberOfThisGroup = (user: LocalStorageUser, search_group: UserType | InastixSystemUserType) => {
	if (!user?.groups)
		return false

	const groups = user.groups
	for (const group of groups) {
		if (group.name === search_group)
			return true
	}
	return false
}

const isAMemberOfThisGroupNormalisedGroups = (user: LocalStorageUser, search_group: UserType | InastixSystemUserType) => {
	if (!user?.normalized_groups)
		return false

	const groups = user.normalized_groups
	for (const group of groups) {
		if (group === search_group)
			return true
	}
	return false
}

const checkUserConnectedGroup = (user: LocalStorageUser, search_group: UserType) => {
	if (!user?.connected_group)
		return false

	if (user.connected_group === search_group) 
		return true

	return false
}


type CompanyIsInGroupType = LocalStorageCompany | Franchisee

const companyIsInGroup = (company: CompanyIsInGroupType, search_group: CompanyType | CompanyType[]) => {
	if (Array.isArray(search_group)) {
		for (const search_group_el of search_group)
			if (companyIsInGroup(company, search_group_el))
				return true
		return false
	}

	if (!company?.groups)
		return false

	return company.groups.some(group => group.name === search_group)
}


export const sortCompanyTerms = () => {
	const connected_company = getLocalStorageCompany()
	if (!connected_company.terms)
		return null
	
	let sorted_terms: Record<string, Term[]> = {}
	for (const term of connected_company.terms) {
		if (sorted_terms.hasOwnProperty(term.country_term_name))
			sorted_terms[term.country_term_name] = [...sorted_terms[term.country_term_name], term]
		else
			sorted_terms[term.country_term_name] = [term]
	}
	return sorted_terms
}


/**
 * Checks if a string contains only alphanumeric characters.
 *
 * @param str - The string to be checked.
 * @returns A boolean indicating whether the string contains only alphanumeric characters.
*/
const hasOnlyAlphanumericChars = (str: string): boolean => {
	return /^[a-zA-Z0-9]+$/.test(str)
}


export function isValidEmail(email: string): boolean {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
	return emailRegex.test(email)
}


export function getWordBeforeAt(email: string): string | null {
	const emailRegex = /^([^\s@]+)@/
	const match = email.match(emailRegex)
	if (match && match.length > 1) {
	  return match[1]
	}
	return null
}


export function capitalizeFirstLetter(word: string) {
	if (word)
		return word.charAt(0).toUpperCase() + word.slice(1)
	return word
}

export function containsLetters(value: string): boolean {
    const letterRegex = /[a-zA-Z]/
    return letterRegex.test(value)
}


// replace all underscores with spaces and capitalize the first letter of each word
export function changeSnakeCaseToWords(snake_case_word: string) {
	return snake_case_word
    .replace(/_/g, ' ') // Replace underscores with spaces globally
    .split(' ') // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ') // Join the array back into a string
}

// replace all spaces with underscores and lowercase the workd
export function transformStringToSnakeCase(input: string): string {
    return input.toLowerCase().replace(/\s+/g, '_');
}

export function transformStringToKebabCase(input: string): string {
    return input.toLowerCase().replace(/\s+/g, '-');
}


export const removeWordFromString = (input_string: string, word_list: string[]): string => {
    const words = input_string.split(/\s+/)  // Split by whitespace to get an array of words
    const filtered_words = words.filter(word => !word_list.includes(word.toLowerCase()))  // Remove unwanted word
    const result_string = filtered_words.join(' ')
    return result_string
}


export function createListOfMonkeynastixTypes(company: LocalStorageCompany | Franchisee | FranchiseOption, capitalize_first_lettler: boolean=true) {
	const true_props = []

	if (company.is_babynastix) true_props.push(capitalize_first_lettler ? "Babynastix": "babynastix")
	if (company.is_monkeynastix) true_props.push(capitalize_first_lettler ? "Monkeynastix": "monkeynastix")
	if (company.is_supernastix) true_props.push(capitalize_first_lettler ? "Supernastix": "supernastix")

	return true_props
}

export function removeKeys<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
    const result = { ...obj }
    keys.forEach(key => delete result[key])
    return result as Omit<T, K>
}

export const findCompanyCurrentTerm = (terms: Term[]): { term: Term, index: number } | null => {
	const current_date = new Date()
	const company_terms: Term[] = terms

    for (let index = 0; index < company_terms.length; index++) {
		const term = company_terms[index]

		const from_date = new Date(term.from_date)
		const to_date = new Date(term.to_date)
		if (from_date < current_date && current_date < to_date)
			return {term, index}
	}

	// UNCOMMMENT FOR DEBUGGING
	// let message = `Could not find the current term in ${JSON.stringify(company_terms)}. Could be mid term break`
	// log.info(message)
	return null
}

export const getCurrentTerm = (terms: Term[], added_days_to_to_date?: number): TermWithIndex => {
	const current_date = new Date()

	for (let i = 0; i < terms.length; i++) {
		const term = terms[i]
		const from_date = new Date(term.from_date)
		const to_date = new Date(term.to_date)

		if (added_days_to_to_date)
			to_date.setDate(to_date.getDate() + added_days_to_to_date)

		if (current_date >= from_date && current_date <= to_date) {
			return  {term, index: i }
		}
	}
	return {term: null, index: null}
}

export const filterTermsByCountryTermId = (country_term_id: number) => {
	const company = getLocalStorageCompany()

	const filtered_terms = company.terms.filter(term => term.country_term === country_term_id)
	return filtered_terms
}

export function getDatesBetween(date1: Date, date2: Date) {
    const dates = []
    let current_date = new Date(date1)

    while (current_date < date2) {
        dates.push(new Date(current_date))
        current_date.setDate(current_date.getDate() + 1)
    }

    return dates
}

export const isWeekendDay = (day: Dayjs) => {
    const dayOfWeek = day.day()  // Sunday is 0, Saturday is 6
    return dayOfWeek === 0 || dayOfWeek === 6  // Sunday or Saturday
}

export function areDatesEqual(date1: Date, date2: Date) {
	return (
	  date1.getFullYear() === date2.getFullYear() &&
	  date1.getMonth() === date2.getMonth() &&
	  date1.getDate() === date2.getDate()
	)
}

export function findLatestTerm(terms: Term[], country_term: number): Term {
    if (terms.length === 0) {
		const company = getLocalStorageCompany()
        new Error(`company ${company.tenant_name} has no terms passed in`)
    }

	terms = terms.filter(term => term.country_term === country_term)
    // Sort terms by 'to_date' in descending order
    const sorted_terms = [...terms].sort((a, b) => new Date(b.to_date).getTime() - new Date(a.to_date).getTime())

    return sorted_terms[0]
}

export function isDateBetween(target_date: Date, start_date: Date, end_date: Date): boolean {
    return target_date >= start_date && target_date <= end_date
}

export function formatDate(date: Date, include_weekday: boolean=true): string {
	// new Date(); // Assuming this is your date object, result will be in format "Sunday, October 29, 2023"

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
	if (include_weekday)
		options.weekday = 'long'

    return date.toLocaleDateString('en-US', options)
}

export const convertDateToYYYYMMDD = (date: string | Date) => {
	if (!(date instanceof Date) && typeof date === 'string') {
		date = new Date(date)
	}

	if (!isNaN(date.getTime())) {
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')

		return `${year}-${month}-${day}`
	} else
		log.error("Invalid date string:", date)
}

export const isWeekend = (date: Date): boolean => {
    const day_of_week = date.getDay()
    return day_of_week === 0 || day_of_week === 6  // Sunday is 0, Saturday is 6
}

export function formatAMPM(date: Date) {
	// format date just to the hours and time and add am or pm
	
    var hours = date.getHours()
    var minutes = date.getMinutes()

    var ampm = hours >= 12 ? 'pm' : 'am'
    let string_minutes

    hours = hours % 12
    hours = hours ? hours : 12 // Handle midnight (0 hours)
    string_minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
    var str_time = hours + ':' + string_minutes + ' ' + ampm
    return str_time
}

/**
 * Converts meters to kilometers.
 * @param meters - The number of meters to convert.
 * @param decimalPlaces - The number of decimal places to round to (default is 2).
 * @returns The equivalent number of kilometers, rounded to the specified decimal places.
*/
function convertMetersToKilometers(meters: number, round: boolean=false, decimalPlaces: number = 2): number {
    if (meters < 0) {
        throw new Error("Meters cannot be negative")
    }

    let kilometers = meters / 1000

	if (round) {
        kilometers = parseFloat(kilometers.toFixed(decimalPlaces))
    }

    return kilometers
}

function convertKilometersToMeters(kilometers: number): number {
    if (kilometers < 0) {
        throw new Error("Kilometers cannot be negative");
    }

    return kilometers * 1000;
}


export {
	getUserGroup,
	isAMemberOfThisGroup,
	isAMemberOfThisGroupNormalisedGroups,
	checkUserConnectedGroup,
	permissionValid,
	companyPermissionValid,
	isInGroup,
	companyIsInGroup,
	hasOnlyAlphanumericChars,
	convertMetersToKilometers,
	convertKilometersToMeters
}