import {useState, useContext} from 'react'

import Typography from '@mui/material/Typography'
import FormControlLabel  from '@mui/material/FormControlLabel'
import Checkbox  from '@mui/material/Checkbox'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { useTranslation } from 'react-i18next'


const ConfirmSendDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('overview')

	const company = getLocalStorageCompany()

	const {setMessage} = useContext(MessageContext)

	const [only_xero, setOnlyXero] = useState(false)

	const handleSend = () => {
		const invoices = state_manager.data.filter(invoice => ids.includes(invoice.id))
 
		const data = {
			invoices: invoices,
			only_xero: only_xero
		}
		const invoice_actions = new InvoiceActions()
		invoice_actions.sendInvoices(data)
		.then(data => {
			console.log(data)
			setMessage(props => ({...props, success: "Invoices Sent"}))
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
		})
		.finally(async () => {
			return state_manager.setGetAction()
			.then(() => {
				handleClose()
			})
		})
	}

	const handleClose = () => {
		onClose()
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOnlyXero(event.target.checked)
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm_send')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('are_you_sure_you_want_to_send')} {ids.length} {ids.length === 1 ? <span>{t('item')}</span> : <span>{t('items')}</span>} {company.is_xero_company && <span> {t('and to Xero')}</span>}?
			</Typography>
			<br/>
			{company.is_xero_company &&
				<FormControlLabel 
					control={
						<Checkbox 
							checked={only_xero} 
							onChange={handleChange} 
						/>
					} 
					label={ids.length === 1 ? <span>{t('send_to_xero_but_not_payer')}</span> : <span>{t('send_to_xero_but_not_payers')}</span>}  
				/>
			}
			<DialogButton variant='contained' onClick={handleSend}> {t('send')} </DialogButton>
		</INastixDialog>
	)
}


const ConfirmSendDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('overview')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('send_invoice')} </Typography>
		</TableActionButon>
		<ConfirmSendDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ConfirmSendDialogHandler