import {useEffect, useState, useCallback} from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import Tooltip from '@mui/material/Tooltip'
import { GridColumnVisibilityModel  } from '@mui/x-data-grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import TopicIcon from '@mui/icons-material/Topic'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Button from '@mui/material/Button'

import { CRUDAction } from '@actions/Actions/actions'
import Table, { TableSelections } from '@components/tables/Table/Table'
import { capitalizeFirstLetter, companyIsInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { UserMap } from '@utils/maps/maps'
import { CompanyType, UserType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import EditUserForm, { UserTableUser } from '@components/forms/UserForm'
import { backend_url } from 'src/config'

import log from 'loglevel'

import './styles.css'
import UploadFilesDialogHandler from './UploadFilesDialogHandler'
import StateManager from '@actions/StateManager/stateManager'
import { minWidth } from '@mui/system'


// Common configuration for authorized columns
export const authorized_column_config = {
	minWidth: 150,
	flex: 2,
	cellClassName: 'center-aligned-cell',
	renderCell: (params: any) => {
		const user: UserTableUser = params.row
		if (user.company_group_name !== CompanyType.FRANCHISEE && params.field === "operator_authorized")
			return <></>
		else if (user.company_group_name !== CompanyType.MASTER_FRANCHISE && params.field === "master_admin_authorized")
			return <></>

		if (params.value === 1) 
			return <CheckCircleIcon sx={{ color: '#51b77f' }} />
		else if (params.value === 2) 
			return <Tooltip title="Pending authorization"><PendingActionsIcon sx={{ color: 'orange' }} /></Tooltip>
		else if (params.value === 3) 
			return <CancelIcon sx={{ color: '#ed586e' }} />
	}
}

export const renderUsernameCell = (params: any) => {
	if (params.field === 'username') {
		const user = getLocalStorageUser()
		if (params.row.email === user.email)
			return <b>You</b>
		return params.value
	}
}

export const renderConnectedCompanyCell = (params: any, key: string='company_name') => {
	if (params.field === key) {
		const company = getLocalStorageCompany()
		if (params.row[key] === company.tenant_name)
			return <b>This franchise</b>
		return params.value
	}
}

export const getGroupValue = (params: any) => {
	if (params.field === 'user_group_name') {
		const user_group_name: UserType = params.value  // shows users highes privilege
		return capitalizeFirstLetter(UserMap[user_group_name])
	}
}

export const getDateTimeValue = (params: any) => {
	if (params.field === 'last_login') {
		const date = new Date(params.value)

		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const currentMonth = currentDate.getMonth()
		const currentDay = currentDate.getDate()
		
		if (
			date.getFullYear() === currentYear &&
			date.getMonth() === currentMonth &&
			date.getDate() === currentDay
		) {
		  // If i's today
			const hours = date.getHours().toString().padStart(2, "0")
			const minutes = date.getMinutes().toString().padStart(2, "0")
			const formattedDate = `Today at ${hours}:${minutes}`
			return formattedDate
		} else {
		  	// If it's not today, check if it's yesterday
			const yesterday = new Date(currentDate)
			yesterday.setDate(currentDay - 1)
		if (
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate()
		) 	{
			// If it's yesterday
			const hours = date.getHours().toString().padStart(2, "0")
			const minutes = date.getMinutes().toString().padStart(2, "0")
			const formattedDate = `Yesterday at ${hours}:${minutes}`
			return formattedDate

		} else {
			// If it's neither today nor yesterday, format it as yyyy-MM-dd HH:mm
			const year = date.getFullYear().toString().padStart(4, "0")
			const month = (date.getMonth() + 1).toString().padStart(2, "0")
			const day = date.getDate().toString().padStart(2, "0")
			const hours = date.getHours().toString().padStart(2, "0")
			const minutes = date.getMinutes().toString().padStart(2, "0")
			const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
			return formattedDate

			}
		}
	}
}


interface RenderDocumentsMenuProps {
    params: any
    anchorElList: (HTMLElement | null)[]
    handleClick: (index: number, event: React.MouseEvent<HTMLButtonElement>) => void
    handleClose: (index: number) => void
	state_manager_callback: StateManager | null
}

export const renderDocumentsMenu: React.FC<RenderDocumentsMenuProps> = ({ params, anchorElList, handleClick, handleClose, state_manager_callback }) => {
	const index = params.row.id
	const documents: { url: string; label: string }[] = []
	let has_at_least_one_missing_document = false

	if (params.row.copy_of_id_photo) {
		documents.push({url: params.row.copy_of_id_photo, label: "Copy of ID photo"})
	}
	else
		has_at_least_one_missing_document = true

	if (params.row.employment_contract) {
		documents.push({url: params.row.employment_contract, label: "Employment contract"})
	}
	else
		has_at_least_one_missing_document = true

	if (params.row.police_clearance_certificate) {
		documents.push({url: params.row.police_clearance_certificate, label: "Police clearance certificate"})
	}
	else
		has_at_least_one_missing_document = true

	if (params.row.south_african_gl_course) {
		documents.push({url: params.row.south_african_gl_course, label: "South African GL Course"})
	}
	else
		has_at_least_one_missing_document = true

	return (
		<div style={{justifyContent: 'left'}}>
		<Box sx={{display: 'flex', gap: '0.5rem'}}>
			{Object.keys(documents).length ? 
				<Tooltip title="View Docs">
					<Button onClick={(event) => handleClick(index, event)} variant='outlined'>
						<TopicIcon/>
					</Button>
				</Tooltip>
				: <></>
			}
			{(has_at_least_one_missing_document && state_manager_callback) ? <UploadFilesDialogHandler id={params.row.user} state_manager={state_manager_callback}/> : <></>}
		</Box>
		<Menu
			anchorEl={anchorElList[index]} // Use the anchorEl corresponding to the current row
			open={Boolean(anchorElList[index])}
			onClose={() => handleClose(index)}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			{documents.map((document, doc_index) => (
				<MenuItem  key={`${doc_index}-${params.row.id}`} sx={{padding: 0}}>
					<Button 
						onClick={() => window.open(`${(backend_url as string).replace(/\/$/, '')}${document.url}`, '_blank', 'noopener,noreferrer')} 
						sx={{width: '100%', justifyContent: 'left', borderBottom: '1px solid lightgrey', borderTop: '0.5px solid lightgrey', borderRadius: 0}}
					>
						{document.label}
					</Button>
				</MenuItem>
			))}
		</Menu>
		</div>
	)
}

export interface TableProps {
	action: CRUDAction
}

const UserTable = (props: TableProps) => {
	const {action} = props

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

    const { t } = useTranslation('instructors')

	const can_edit_users = permissionValid(user, UserPermissions.EDIT_USERS)
	const can_delete_users = permissionValid(user, UserPermissions.DELETE_USERS)
	const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)

	const use_pagination = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) && !company.acts_like_a_franchise ? true : false
	const filterable = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) && !company.acts_like_a_franchise ? false : true

	const filter_sort = {
		filterable: filterable,
		sortable: false
	}

	const sort = {
		sortable: !use_pagination
	}

    const [can_edit, setCanEdit] = useState<boolean>(can_edit_users)
    const [can_delete, setCanDelete] = useState<boolean>(can_delete_users)
	const [state_manager_callback, setStateManagerCallback] = useState<StateManager | null>(null)

    const [table_selections, setTableSelections] = useState<TableSelections>({
        row_selection_model: [],
        selections: []
    })

	const [anchorElList, setAnchorElList] = useState<(HTMLElement | null)[]>([])
	const handleClick = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
		const updatedAnchorElList = [...anchorElList]
		updatedAnchorElList[index] = event.currentTarget
		setAnchorElList(updatedAnchorElList)
	}

	const handleClose = (index: number) => {
		const updatedAnchorElList = [...anchorElList]
		updatedAnchorElList[index] = null
		setAnchorElList(updatedAnchorElList)
	}

    const setSelectionsCallback = (data: TableSelections) => {
		setTableSelections(data)
	}

	const isUserInConnectedCompany = useCallback(async (user_id: number) => {
		const user_actions = new UserActions()
	
		try {
			const connected_companies: LocalStorageCompany[] = await user_actions.getCompanies(user_id)
			const found_company = connected_companies.find(connected_company => connected_company.company_uuid === company.company_uuid)

			if (found_company)
				return true
			else
				return false

		} catch (error: any) {
			log.error(error.message)
			return false
		}
	}, [company])

	useEffect(() => {
		const checkUserInCompany = async () => {
			if (!table_selections.selections.length) {
				setCanEdit(can_edit_users)
				setCanDelete(can_delete_users)
			} else {
				const selected_user = table_selections.selections[0] as UserTableUser
				if (selected_user.email === user.email) {
					setCanEdit(false)
					setCanDelete(false)
				}
				else if (!is_franchisor && !await isUserInConnectedCompany(selected_user.user)) {
					setCanEdit(false)
					setCanDelete(false)
				}
				else {
					setCanEdit(can_edit_users)
					setCanDelete(can_delete_users)
				}
			}
		}
		checkUserInCompany()
	}, [table_selections, user, can_edit_users, can_delete_users, is_franchisor, isUserInConnectedCompany])

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 50, flex: 2  },
		{ field: 'user_id', headerName: 'User ID', minWidth: 100, flex: 2 },
		{ field: 'username', headerName: t('name'), ...sort, renderCell: renderUsernameCell, minWidth: 200, flex: 2 },
		{ field: 'company_name', headerName: t('franchise_school'), ...sort, renderCell: renderConnectedCompanyCell, minWidth: 200, flex: 2 },
		{ field: 'user_group_name', headerName: t('role'), ...filter_sort, valueGetter: getGroupValue, minWidth: 200, flex: 2 },
		{ field: 'email', headerName: t('contact'), ...sort,  minWidth: 250, flex: 2},
		{ field: 'documents', headerName: "Documents", minWidth: 180, filterable: false, sortable: false, renderCell: (params: any) => renderDocumentsMenu({params, anchorElList, handleClick, handleClose, state_manager_callback})},
		{ field: 'master_admin_authorized', headerName: t('mfo_authorized'), filterable: false, sortable: false, description: t('master_franchise_operator_authorized'), ...authorized_column_config},	
		{ field: 'operator_authorized', headerName: t('fo_authorised'), filterable: false, sortable: false, description: t('franchise_operator_authorized'), ...authorized_column_config},
		{ field: 'monkeynastix_authorized', headerName: t('mn_authorised'), filterable: false, sortable: false, description: t('monkeynastix_authorised'), ...authorized_column_config},
		{ field: 'date_created', headerName: t('date_created'), minWidth:200, flex:2},
		{ field: 'date_modified', headerName: t('date_modified'), minWidth:200, flex:2},
		{ field: 'last_login', headerName: t('last_login'), filterable: false, sortable: false, valueGetter: getDateTimeValue, minWidth: 200, flex: 2}
	]

	const column_visibility_model: GridColumnVisibilityModel = {}

	const is_school_or_franchise = companyIsInGroup(company, [CompanyType.SCHOOL, CompanyType.FRANCHISEE])
	if (is_school_or_franchise) {
		column_visibility_model['company_name'] = false
	}

	column_visibility_model['user_id'] = false

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'username') {
			field = 'username'
		}
		else if (field === 'company_name') {
			field = 'tenants'
		}
		else if (field === 'email') {
			field = 'email'
		}
		return {field, value, custom_operator}
	}

	const paginationSortParser = (field: string) => {
		if (field === 'connected_company_name') {
			field = 'connected_company__tenant_name'
		}

		return field
	}

    return (
		<>
        <Table
			action={action}
			columns={columns}
			Form={EditUserForm}
			column_visibility_model={column_visibility_model}
			paginationFilterParser={use_pagination ? paginationFilterParser : undefined}
			paginationSortParser={use_pagination ? paginationSortParser: undefined}
			use_pagination={use_pagination}
			page_size={25}
			amount_of_rows_selection={[25, 50]}
			can_edit={can_edit}
			can_delete={can_delete}
			setSelectionsCallback={setSelectionsCallback}
			setStateManagerCallback={setStateManagerCallback}	
			filter={{user__archive: 'False'}}
		/>
		</>
    )
}

export default UserTable