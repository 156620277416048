import {useEffect, useState, useRef } from 'react'

import html2pdf from 'html2pdf.js'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Pages from '@pages/Page'
import { Typography } from '@mui/material'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { PageHeadingTypography } from '@styles/Typography'
import MemberHandoutsActions from '@actions/MemberHandoutsActions/franchiseCostingActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { formatDate } from '@utils/utils/util'
import XeroLogo from '@images/xero/logo/logo.png'

import { memberHandoutsTableStyles } from './styles'
import { parseNumberToMoneyValue, parseToMoneyValue } from '@utils/numbers/numbers'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { SchoolType } from '@utils/enums/enums'


// interface PurchaseDetails {
//     UnitPrice: number
//     AccountCode: number
// }

// interface SalesDetails {
//     UnitPrice: number
//     AccountCode: number
// }

interface MemberHandoutItemPartial {
    ItemID: string
    Code: string
    Item: string
    Ratio: string
    PurchaseDetails: string
    SalesDetails: string
}

interface MemberHandoutCategoryResponse {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: string[]
}

interface MemberHandoutCategoryPartial {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: MemberHandoutItemPartial[]
}

interface MemberHandoutItemFull {
    ItemID: string
    Code: string
    Item: string
    Ratio: string
    PurchaseDetails: string
    SalesDetails: string
    CostPerChild: number
    QtyToOrder: number | string 
    TotalCostPerItem: number
    Percentage: number | string | null
}

interface MemberHandoutCategoryFull {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: MemberHandoutItemFull[]
}


const currentDate = new Date()
const year = currentDate.getFullYear()

const MemberHandoutsPage = () => {
    const company = getLocalStorageCompany()

    const tableRef = useRef(null)

    const [handout_categories, setHandoutCategories] = useState<MemberHandoutCategoryFull[] | null>(null)
    const [num_of_children, setNumOfChildren] = useState<number | null>(null)
    const [program_type, setProgramType] = useState<SchoolType | ''>(SchoolType.MONKEYNASTIX)

    useEffect(() => {
        if (num_of_children === null)
            return

        const member_handouts_actions = new MemberHandoutsActions()
        member_handouts_actions.getMemberHandoutItems()
        .then((member_handout_categories_response: MemberHandoutCategoryResponse[]) => {
            const member_handout_categories_partial: MemberHandoutCategoryPartial[] = member_handout_categories_response.map(category_response => {
                return {
                    ...category_response,
                    member_handouts_items: category_response.member_handouts_items.map(member_handouts_item => JSON.parse(member_handouts_item))
                }
            })

            let total_qty_to_ratio = 0
            let total_cost_per_child = 0
            let total_cost = 0
            let total_percentage = 0

            let member_handout_categories_full: MemberHandoutCategoryFull[] = member_handout_categories_partial.map(handout_category_partial => {
                return {
                    ...handout_category_partial,
                    member_handouts_items: handout_category_partial.member_handouts_items.map(member_handout_item => {
                        const qty_to_order = calcQtyToOrder(member_handout_item, num_of_children)
                        const cost_per_child = calcCostPerChild(member_handout_item)
                        const total_cost_per_item = calcTotalCostForItem(member_handout_item, qty_to_order)

                        const ratio = parseToMoneyValue(member_handout_item.Ratio) as number
                        total_qty_to_ratio = total_qty_to_ratio + ratio
                        total_cost_per_child = total_cost_per_child + cost_per_child
                        total_cost = total_cost + total_cost_per_item

                        return {
                            ...member_handout_item,
                            CostPerChild: cost_per_child,
                            QtyToOrder: qty_to_order,
                            TotalCostPerItem: total_cost_per_item,
                            Percentage: null
                        }
                    })
                }
            })

            member_handout_categories_full = member_handout_categories_full.map(handout_category_partial => {
                return {
                    ...handout_category_partial,
                    member_handouts_items: handout_category_partial.member_handouts_items.map(member_handout_item => {
                        const percentage = parseNumberToMoneyValue((member_handout_item.TotalCostPerItem / total_cost) * 100) as number
                        total_percentage = parseNumberToMoneyValue(total_percentage + percentage) as number
                        return {
                            ...member_handout_item,
                            Percentage: percentage
                        }
                    })
                }
            })

            member_handout_categories_full.push({
                id: 0,
                category_name: "Total",
                category_type: "Monkeynastix",
                member_handouts_items: [{
                    ItemID: "",
                    Code: "",
                    Item: "",
                    Ratio: total_qty_to_ratio.toString(),
                    PurchaseDetails: "",
                    SalesDetails: "",
                    CostPerChild: total_cost_per_child,
                    QtyToOrder: "",
                    TotalCostPerItem: total_cost,
                    Percentage: (99.98 <= total_percentage && total_percentage <= 100.02) ? "100" : "Inaccurate "
                }]
            })

            setHandoutCategories(member_handout_categories_full)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [num_of_children])

    useEffect(() => {
        async function getNumOfChildren() {
            try {
                const child_actions = new ChildActions()
                const num_of_children = await child_actions.get(undefined, undefined, { num_of_rows_only: true })
                setNumOfChildren(num_of_children)
            } catch (error) {
                console.error('Error fetching number of children:', error)
            }
        }

        getNumOfChildren()
            
    }, [])

    const handleProgramTypeChange = (event: SelectChangeEvent) => {
        setProgramType(event.target.value as SchoolType)
    }

    const handlePrintPDF = () => {
        const element = tableRef.current

        const opt = {
            filename: 'Handouts Budget.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' } 
        }

        html2pdf().from(element).set(opt).save()
    }

    const handlSyncItemsWithXeroClick = () => {
        const member_handouts_actions = new MemberHandoutsActions()
        member_handouts_actions.syncMemberHandoutItemsToXero()
        .then(() => {

        })
        .catch((error) => {
            console.log(error)
        })
    }
    
    const calcCostPerChild = (handout_item: MemberHandoutItemPartial) => {
        return parseNumberToMoneyValue(parseFloat(handout_item.Ratio) * parseFloat(handout_item.PurchaseDetails)) as number
    }

    const calcQtyToOrder = (handout_item: MemberHandoutItemPartial, num_of_children: number) => {
        const qty_to_order = parseNumberToMoneyValue(parseFloat(handout_item.Ratio) * num_of_children) as number
        return qty_to_order
    }

    const calcTotalCostForItem = (handout_item: MemberHandoutItemPartial, qty_to_order: number) => {
        const total_cost_for_item = parseNumberToMoneyValue(parseFloat(handout_item.PurchaseDetails) * qty_to_order) as number
        return total_cost_for_item
    }

    const isFirstTable = (index: number) => {
        return index === 0
    }

    const isLastTable = (index: number, handout_categories: MemberHandoutCategoryFull[]) => {
        return index + 1 === handout_categories.length
    }

    const getBackgroundColor = () => {
        if (program_type === SchoolType.MONKEYNASTIX)
            return '#fbeab8'
        else if (program_type === SchoolType.BABYNASTIX)
            return '#abbefd'
        else if (program_type === SchoolType.SUPERNASTIX)
            return '#a9f9bc'
    }

    memberHandoutsTableStyles.headerCell = {
        border: '1px solid gray',
        backgroundColor: getBackgroundColor(),
        fontSize: '0.7rem',
        textAlign: 'left',
        minWidth: '15ch',
        padding: '2px 4px'
    }

	return (
        <>
        <Pages page_title="Member Handouts">
			<PageHeadingTypography> Member Handouts </PageHeadingTypography>
            <Box sx={{gap: '1rem', display: 'flex', alignItems: 'flex-start', marginBottom: '1rem'}}>
                <FormControl sx={{width: '20ch', marginRight: 'auto'}} size="small">
                    <InputLabel>Program Type</InputLabel>
                    <Select
                        value={program_type}
                        onChange={handleProgramTypeChange}
                        label="Program Type"
                    >
                        {Object.values(SchoolType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button onClick={handlSyncItemsWithXeroClick} sx={{border: '1px solid lightgrey', padding: "0.2rem 1rem", textTransform: 'none', gap: 1, borderRadius: 2, color: 'darkslategray'}}> 
                    {<img src={XeroLogo} alt="A blue Xero logo" width='20px'/>}
                    Sync Xero Items 
                </Button>
                <Button 
                    onClick={handlePrintPDF} 
                    sx={{border: '1px solid lightgrey', padding: "0.2rem 1rem", textTransform: 'none', gap: 1, borderRadius: 2, color: 'darkslategray'}}
                    startIcon={<LocalPrintshopIcon/>}
                > 
                    Print as PDF
                </Button>
            </Box>
            <Box sx={{padding: '1rem'}} ref={tableRef}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem'}}> 
                    <Typography sx={{fontSize: '1.2rem', fontWeight: 900, textTransform: 'uppercase'}}> {program_type} </Typography>    
                    <Typography sx={{fontSize: '1rem', fontWeight: 900, textTransform: 'uppercase'}}> {company.tenant_name} </Typography>    
                    <Typography sx={{fontSize: '1.2rem', fontWeight: 900, textTransform: 'uppercase'}}> {year} </Typography>    
                    <Typography sx={{fontSize: '0.8rem'}}> {formatDate(currentDate, false)} </Typography>    
                </Box>
                {handout_categories &&
                    <div >
                        {handout_categories.map((handout_category, index) => (
                            <table key={handout_category.id} style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                        <tr>
                                        {isFirstTable(index) && (
                                            <>
                                                <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell}}></th>
                                                <th colSpan={3} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    Per Child
                                                </th>
                                                <th colSpan={2} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    Totals
                                                </th>
                                                <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    %
                                                </th>
                                                <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell, border: 'none', backgroundColor: 'none'}}>
                                                    Amount Paid
                                                </th>
                                            </>
                                        )}
                                        </tr>
                                        <tr>
                                            <th style={{...memberHandoutsTableStyles.headerCell, minWidth: '20ch'}}>{handout_category.category_name}</th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Qty <span style={memberHandoutsTableStyles.bracketText}>(Per Child/Annum)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Item)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Child/Annum)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Qty to Order</th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Item)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Percentage <span style={memberHandoutsTableStyles.bracketText}>(Item to Budget)</span></th>
                                            <th style={{...memberHandoutsTableStyles.headerCell, textAlign: 'center'}}>Amounts</th>
                                            <th style={{...memberHandoutsTableStyles.headerCell, textAlign: 'center'}}>STOCK</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {handout_category.member_handouts_items.map((handout_item) => (
                                        <tr key={handout_item.Code}>
                                            <td style={{
                                                ...memberHandoutsTableStyles.cell, textAlign: 'left', minWidth: '20ch',
                                                ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                            }}>
                                                {handout_item.Item}</td>
                                            <td style={{...memberHandoutsTableStyles.cell, textAlign: 'center'}}>{handout_item.Ratio}</td>
                                            <td style={{
                                                ...memberHandoutsTableStyles.cell, 
                                                ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                            }}>
                                                {handout_item.PurchaseDetails ? "R " : ""}
                                                {handout_item.PurchaseDetails}
                                            </td>
                                            <td style={memberHandoutsTableStyles.cell}>R {handout_item.CostPerChild}</td>
                                            <td style={{
                                                ...memberHandoutsTableStyles.cell, 
                                                ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                            }}>
                                                {handout_item.QtyToOrder}
                                            </td>
                                            <td style={
                                                index + 1 === handout_categories.length ? {... memberHandoutsTableStyles.cell, borderBottom: '5px double #000', borderTop: '2px solid #000' } : memberHandoutsTableStyles.cell
                                            }>
                                                R {handout_item.TotalCostPerItem}
                                            </td>
                                            <td style={memberHandoutsTableStyles.cell}>{handout_item.Percentage}%</td>
                                            <td style={{
                                                ...memberHandoutsTableStyles.cell, 
                                                ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.mainResultValue : {})
                                            }}></td>
                                            <td style={{
                                                ...memberHandoutsTableStyles.cell, 
                                                ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.mainResultValue : {})
                                            }}></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                    </div>
                }
            </Box>
        </Pages>
        </>
	)
}

export default MemberHandoutsPage
