import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'


const FranchiseCostingPage = () => {
    // const [orders, setOrders] = useState<FZoneOrder[]>([])

    useEffect(() => {

    }, [])

	return (
        <>
        <Pages page_title="Franchise Costing">
			<PageHeadingTypography> Franchise Costing </PageHeadingTypography>
            
        </Pages>
        </>
	)
}

export default FranchiseCostingPage
