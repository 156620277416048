import { useTranslation } from 'react-i18next'

import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

import { ChildAuthorisationMap } from '@utils/maps/maps'
import { ChildAuthorisation } from '@utils/interfaces/interfaces'
import AccessTime from '@mui/icons-material/AccessTime'


interface ChildAuthorisationSelectProps {
    child_authorisation_select?: ChildAuthorisation | string
    handleChildAuthorisationChange?: (event: SelectChangeEvent) => void
}

const ChildAuthorisationSelect = (props: ChildAuthorisationSelectProps) => {
    const { t } = useTranslation('child_form')
    const {child_authorisation_select, handleChildAuthorisationChange} = props

    return (
        <FormControl fullWidth>
            <InputLabel id="child-authorisation-select-label">{t('authorisation')}</InputLabel>
            <Select
                labelId="child-authorisation-select-label"
                id="child-authorisation-select"
                name="authorized"
                value={child_authorisation_select}
                label={"Authorisation"}
                onChange={handleChildAuthorisationChange}
                sx={{textTransform: 'capitalize', '.MuiSelect-select': {
                    display: 'flex',
                    marginTop: '0.3rem',
                    gap: '1rem'
                }}}
                required
            >
                <MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={ChildAuthorisation.AUTHORISED}>
                    <> <CheckCircleIcon sx={{color:"forestgreen"}}/> {ChildAuthorisationMap[ChildAuthorisation.AUTHORISED]} </>
                </MenuItem>
                <MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={ChildAuthorisation.AWAITING_PAYMENT}>
                    <> <PendingActionsIcon sx={{color:"orange"}}/> {ChildAuthorisationMap[ChildAuthorisation.AWAITING_PAYMENT]} </>
                </MenuItem>
                <MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={ChildAuthorisation.UNAUTHORISED}>
                    <> <CancelIcon sx={{color:"red"}}/> {ChildAuthorisationMap[ChildAuthorisation.UNAUTHORISED]} </>
                </MenuItem>
                <MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={ChildAuthorisation.ON_HOLD}>
                    <> <AccessTime sx={{color:"blue"}}/> {ChildAuthorisationMap[ChildAuthorisation.ON_HOLD]} </>
                </MenuItem>
                <MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={ChildAuthorisation.LEFT}>
                    <> <DoNotDisturbIcon sx={{color:"red"}}/> {ChildAuthorisationMap[ChildAuthorisation.LEFT]} </>
                </MenuItem>
            </Select>
        </FormControl>
    )
}

export default ChildAuthorisationSelect