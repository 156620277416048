import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import SummarizeIcon from '@mui/icons-material/Summarize'
import Box from '@mui/material/Box'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'
import SelectTermIndex from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectTermIndex'
import { CertificateDialogLocalstorageData } from '@components/dialogs/MemberCertificatesDialogHandler'
import SelectCountryTermsOnly from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectCountryTermOnly'
import DateRangePickerNastix from '@components/pickers/DateRangeNastix'


const FranchiseChildAuthorisationReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [child_display_filter, setChildDisplayFilter] = useState('1')
	const [country_term_id, setCountryTermId] = useState<number | 'All' | null>(null)
    const [term_index, setTermIndex] = useState<number | null>(null)

	const handleChildDisplayFilterChange = (event: SelectChangeEvent) => {
		setChildDisplayFilter(event.target.value as string)
	}

	const handleReportCreate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		event.stopPropagation()
	
		setNumOfRequests(props => props + 1)
	
		const data = {
			member_display_filter: child_display_filter,
			franchise_ids: ids,
			country_term_id: country_term_id,
			term_index: term_index
		}

		const excel_report_actions = new ExcelReportActions()

		try {
			const response = await excel_report_actions.createChildAuthorisationReport(data)
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Member Authorisation Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error generating Child Certificates Report:', error)
			setNumOfRequests(props => props - 1)
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Member Authorisation </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={(event) => handleReportCreate(event)}>
				<FormControl sx={{marginBottom: '1rem', width: '25ch', marginTop: '1rem'}}>
					<InputLabel>Member Display Filter</InputLabel>
					<Select
						label="Member Display Filter"
						value={child_display_filter}
						name="member_display_filter"
						onChange={handleChildDisplayFilterChange}
					>
						<MenuItem value={'1'}>Overview</MenuItem>
						<MenuItem value={'2'}>Detailed</MenuItem>
						<MenuItem value={'3'}>By School</MenuItem>
					</Select>
				</FormControl>
				<Box display='flex' gap={'2rem'}>
					<SelectCountryTermsOnly country_term_id={country_term_id} setCountryTermId={setCountryTermId}/>
					<SelectTermIndex country_term_id={country_term_id} term_index={term_index} setTermIndex={setTermIndex}/>
				</Box>
				<Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        	</form>
        </INastixDialog>
	)
}


const FranchiseChildAuthorisationReportDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Authorisation Report</Typography>
		</TableActionButon>
		<FranchiseChildAuthorisationReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default FranchiseChildAuthorisationReportDialogHandler