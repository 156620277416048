import React, {useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { IconButton, Typography} from '@mui/material'
import Badge from '@mui/material/Badge'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import FZoneEcommerceOrderActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceOrderActions'
import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'

import { CartContext } from '../contexts'
import { FZoneProduct } from '../Ecommerce'
import { orderStatusUIStrings } from './maps'
import { OrderStatus } from './enum'


export interface FZoneOrderItem {
    id: number
    product: number
    order: number
    quantity: number
    date_added: string
}

export interface FZoneOrder {
    id: number
    order_hex_id: string
    date_ordered: string
    complete: boolean
    status: OrderStatus
    order_items: FZoneOrderItem[]
    user: number
    username: string
    tenant: number
}

type OrdersObject = {
    [key: string]: boolean
}


const FzoneEcommerceOrdersPage = () => {
    const navigation = useNavigate()

    const {cart} = useContext(CartContext)

    const [orders, setOrders] = useState<FZoneOrder[]>([])
    const [open_orders, setOpenOrders] = useState<Record<string, boolean> | null>(null)
    const [products, setProducts] = useState<FZoneProduct[]>([])

    const handleOpenOrdersClick = (order_id: number) => {
        setOpenOrders(props => (props ? {...props, [order_id.toString()]: !props[order_id.toString()]} : null ))
    }

    useEffect(() => {
        const order_actions = new FZoneEcommerceOrderActions()

        order_actions.get()
        .then((orders: FZoneOrder[]) => {
            setOrders(orders)

            const ordersObject: { [key: string]: boolean } = orders.reduce((acc: OrdersObject, order: FZoneOrder) => {
                acc[order.id.toString()] = false
                return acc
            }, {})
            setOpenOrders(ordersObject)
        })

    }, [])

    useEffect(() => {
        const product_actions = new FZoneEcommerceProductActions()
        product_actions.get()
        .then((products: FZoneProduct[]) => {
            setProducts(products)
        })

    }, [])

    const goToCheckout = () => {
        navigation('/fzone/checkout')
    }

	return (
        <>
        <Badge badgeContent={cart ? cart.products.length : 0} color="primary" sx={{top: '25px', right: '30px', position: 'absolute'}}>
            <IconButton onClick={goToCheckout}>
                <ShoppingCartIcon/>
            </IconButton>
        </Badge>
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5' color='darkslategray' marginBottom={'2rem'}> Orders </Typography>
            {orders.length ? 
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Ordered By</TableCell>
                            <TableCell align="center">Order ID</TableCell>
                            <TableCell align="center">Order Status</TableCell>
                            <TableCell align="center">Date Ordered</TableCell>
                            <TableCell align="center">View Order Details</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orders.map(order => {
                            return (
                                <React.Fragment key={order.id}>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {order.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.order_hex_id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {orderStatusUIStrings[order.status]}
                                        {/* {order.complete ? 
                                            <Typography sx={{color: 'forestgreen'}}> Completed </Typography>
                                            :
                                            <Typography sx={{color: 'red'}}> Not completed </Typography>
                                        }    */}
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.date_ordered}
                                    </TableCell>
                                    <TableCell align="center">
                                        <ListItemButton onClick={() => handleOpenOrdersClick(order.id)} sx={{width: '20%', margin: 'auto', justifyContent: 'center'}}>
                                            {open_orders ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={5} sx={open_orders && open_orders[order.id.toString()] ? {padding: '1rem'} : {padding: 0}}>
                                        <Collapse in={open_orders ? open_orders[order.id.toString()] : false} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Order Details
                                                </Typography>
                                                <Table size="small" aria-label="order-details-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Product Name</TableCell>
                                                            <TableCell align="right">Price</TableCell>
                                                            <TableCell align="right">Quantity</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {order.order_items.map((order_item) => {
                                                            const product = products.find((prod) => prod.id === order_item.product)

                                                            if (!product) return null

                                                            return (
                                                                <TableRow key={order_item.id}>
                                                                    <TableCell>{product.name}</TableCell>
                                                                    <TableCell align="right">{product.price}</TableCell>
                                                                    <TableCell align="right">{order_item.quantity}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                : 
                <Typography> No orders to show </Typography>
            }
            </Box>
        </>
	)
}

export default FzoneEcommerceOrdersPage
