import {useState, useContext} from 'react'

import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'

import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { useTranslation } from 'react-i18next'
import FranchisorRoyaltyActions from '@actions/Finance/FranchisorInvoiceActions/franchisorInvoiceActions'
import RoyaltyInvoiceActions from '@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions'
import { Dayjs } from 'dayjs'
import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'


const GenerateRoyaltyInvoicesDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('overview')

	const company = getLocalStorageCompany()

	const {setMessage} = useContext(MessageContext)

    const [due_date, setDueDate] = useState<Record<string, Dayjs | null>>({
        'due_date': null
    })

	const handleGenerate = () => {
		
        const data = {
            due_date: due_date
        }

        console.log(company)

		if (companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)) {
			console.log('Generating royalty invoices')
			const royalty_invoice_actions = new RoyaltyInvoiceActions()
			royalty_invoice_actions.create(data)
			.then(data => {
				console.log(data)
				setMessage(props => ({...props, success: t("invoices_generated")}))
			})
			.catch(error => {
				setMessage(props => ({...props, error: error.message}))
			})
			.finally(async () => {
				return state_manager.setGetAction(undefined, undefined, {'approved': 'False'})
				.then(() => {
					handleClose()
				})
			})
		}


		if (companyIsInGroup(company, CompanyType.FRANCHISOR)) {
			console.log('Generating franchisor royalty invoices')
			const franchisor_royalty_invoice_actions = new FranchisorRoyaltyActions()
            franchisor_royalty_invoice_actions.create(data)
			.then(data => {
				console.log(data)
				setMessage(props => ({...props, success: t("invoices_generated")}))
			})
			.catch(error => {
				setMessage(props => ({...props, error: error.message}))
			})
			.finally(async () => {
				return state_manager.setGetAction(undefined, undefined, {'approved': 'False'})
				.then(() => {
					handleClose()
				})
			})
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('please_select_a_due_date_for_the_invoices_being_generated')}
			</Typography>
            <DatePickerObjectNastix
                disabled={false}
                dates={due_date}
                setDates={setDueDate}
                dates_key='due_date'
                label={t('due_date')}
                use_mobile={true}
            />
			<DialogButton variant='contained' onClick={handleGenerate}> {t('generate')} </DialogButton>
		</INastixDialog>
	)
}


const GenerateRoyaltyInvoicesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('overview')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<AddIcon/>}>
			<Typography> {t('generate')} </Typography>
		</TableActionButon>
		<GenerateRoyaltyInvoicesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default GenerateRoyaltyInvoicesDialogHandler