import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { INASTIX_SYSTEM_MEMBER_HANDOUTS_ENDPOINT, INASTIX_SYSTEM_MEMBER_HANDOUTS_SYNC_ITEMS_ENDPOINT } from "@adapters/routes/endpoints"


class MemberHandoutsHandler extends BaseHandler {
    endpoint: string
    sync_items_endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = INASTIX_SYSTEM_MEMBER_HANDOUTS_ENDPOINT
        this.sync_items_endpoint = INASTIX_SYSTEM_MEMBER_HANDOUTS_SYNC_ITEMS_ENDPOINT
    }

    getMemberHandoutItems (): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.get(parsed_endpoint)
    }

    syncMemberHandoutItemsToXero (): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.sync_items_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, {})
    }
}

export default MemberHandoutsHandler