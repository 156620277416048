import { useContext, useState } from "react"

import VerifiedIcon from '@mui/icons-material/Verified'
import { Typography } from "@mui/material"
import { TableActionButon } from "@styles/Buttons"
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog"

import { DialogComponentProps } from "@components/tables/Table/Table"
import { MessageContext } from "@contexts/MessageContext"
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { useTranslation } from "react-i18next"
import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions"


const ChildYearlyAuthorisation = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('payments')

    const {setMessage} = useContext(MessageContext)

    const handleSend = () => {
        
        const child_payment_actions = new ChildPaymentsActions()
        const data = {
            'ids': ids,
            'payment_structure': 'yearly'

        }
        child_payment_actions.create(data)
        .then(data => {
            console.log(data)
            setMessage(props => ({...props, success: t("children_marked_as_paid")}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
        .finally(async () => {
            return state_manager.setGetAction(undefined, undefined, {'payment_structure': 'yearly'})
            .then(() => {
                handleClose()
            })
        })
    }
    
	const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('are_you_sure_you_would_like_to_mark_these_children_as_authorised_for_the_year')}
			</Typography>
			<DialogButton variant='contained' onClick={handleSend}> {t('confirm')} </DialogButton>
		</INastixDialog>
	)
}

const ChildYearlyAuthorisationHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    const { t } = useTranslation('payments')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('authorise')} </Typography>
            </TableActionButon>
            <ChildYearlyAuthorisation ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
        </>
    )
}

export default ChildYearlyAuthorisationHandler