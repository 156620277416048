import SchoolTable from "@components/tables/CRUD/SchoolTable"
import SchoolActions from "@actions/CRUDActions/SchoolActions/schoolActions"
import ChildCertificatesReportDialogHandler from '@components/dialogs/Reports/ChildCertificates/ChildCertificatesReportDialogHandler'

import ReportingPage from ".."


const ReportCertificates = () => {
	const school_actions = new SchoolActions()

    return (
        <ReportingPage>
            <SchoolTable action={school_actions} use_as_reporting={true} dialogs={[ChildCertificatesReportDialogHandler]}/>
        </ReportingPage>
    )
}

export default ReportCertificates